import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full',
  },
  {
    path: 'inicio',
    loadChildren: () =>
      import('./inicio/inicio.module').then((m) => m.InicioPageModule),
  },
  {
    path: 'ingresar',
    loadChildren: () =>
      import('./ingresar/ingresar.module').then((m) => m.IngresarPageModule),
  },
  {
    path: 'pagos',
    loadChildren: () =>
      import('./pagos/pagos.module').then((m) => m.PagosPageModule),
  },
  {
    path: 'facturacion/:FOLIO',
    loadChildren: () =>
      import('./facturacion/facturacion.module').then(
        (m) => m.FacturacionPageModule
      ),
  },
  {
    path: 'facturacion',
    loadChildren: () =>
      import('./facturacion/facturacion.module').then(
        (m) => m.FacturacionPageModule
      ),
  },
  {
    path: 'metodos/:recibo/:total',
    loadChildren: () =>
      import('./pagos/metodos/metodos.module').then((m) => m.MetodosPageModule),
  },
  {
    path: 'metodos-iframe',
    loadChildren: () =>
      import('./pagos/metodos-iframe/metodos-iframe.module').then(
        (m) => m.MetodosIframePageModule
      ),
  },
  {
    path: 'codigo/:estatus/:ECI/:XID/:CAVV/:REFERENCE3D/:recibo/:tarjeta/:exp/:tipo',
    loadChildren: () =>
      import('./pagos/ccv/ccv.module').then((m) => m.CcvPageModule),
  },
  {
    path: 'rpt-menu',
    loadChildren: () =>
      import('./rpt-menu/rpt-menu.module').then((m) => m.RptMenuPageModule),
  },
  {
    path: 'rpt-cobros-sapal-indicadores',
    loadChildren: () =>
      import(
        './rpt-cobros-sapal-indicadores/rpt-cobros-sapal-indicadores.module'
      ).then((m) => m.RptCobrosSapalIndicadoresPageModule),
  },
  {
    path: 'documentacion/QR/carta-no-adeudo',
    loadChildren: () =>
      import('./documentacion/QR/carta-no-adeudo/carta-no-adeudo.module').then(
        (m) => m.CartaNoAdeudoPageModule
      ),
  },
  {
    path: 'documentacion/QR/carta-no-servicio',
    loadChildren: () =>
      import(
        './documentacion/QR/carta-no-servicio/carta-no-servicio.module'
      ).then((m) => m.CartaNoServicioPageModule),
  },
  {
    path: 'documentacion/QR/empleado/:CEM_CLAVE',
    loadChildren: () =>
      import('./documentacion/QR/contrato/contrato.module').then(
        (m) => m.ContratoPageModule
      ),
  },
  {
    path: 'documentacion/QR/cambio-de-nombre',
    loadChildren: () =>
      import(
        './documentacion/QR/cambio-de-nombre/cambio-de-nombre.module'
      ).then((m) => m.CambioDeNombrePageModule),
  },
  {
    path: 'documentacion/QR/cancelacion-de-servicio',
    loadChildren: () =>
      import(
        './documentacion/QR/cancelacion-de-servicio/cancelacion-de-servicio.module'
      ).then((m) => m.CancelacionDeServicioPageModule),
  },
  {
    path: 'documentacion/QR/:FOLIO_CLAVE',
    loadChildren: () =>
      import('./documentacion/QR/orden-de-pago/orden-de-pago.module').then(
        (m) => m.OrdenDePagoPageModule
      ),
  },
  {
    path: 'documentacion/QR/factibilidad',
    loadChildren: () =>
      import('./documentacion/QR/factibilidad/factibilidad.module').then(
        (m) => m.FactibilidadPageModule
      ),
  },
  {
    path: 'documentacion/QR/borron-y-cuenta-nueva',
    loadChildren: () =>
      import(
        './documentacion/QR/borron-y-cuenta-nueva/borron-y-cuenta-nueva.module'
      ).then((m) => m.BorronYCuentaNuevaPageModule),
  },
  {
    path: 'documentacion/QR/QyS',
    loadChildren: () =>
      import('./documentacion/QR/qy-s/qy-s.module').then(
        (m) => m.QySPageModule
      ),
  },
  {
    path: 'documentacion/QR/Ticket',
    loadChildren: () =>
      import('./documentacion/QR/ticket/ticket.module').then(
        (m) => m.TicketPageModule
      ),
  },
  {
    path: 'documentacion/QR/recibo-de-pago',
    loadChildren: () =>
      import('./documentacion/QR/recibo-de-pago/recibo-de-pago.module').then(
        (m) => m.ReciboDePagoPageModule
      ),
  },

  {
    path: 'r3dsecurestatus',
    loadChildren: () =>
      import('./pagos/r3dsecurestatus/r3dsecurestatus.module').then(
        (m) => m.R3dsecurestatusPageModule
      ),
  },
  {
    path: '**',
    redirectTo: 'inicio',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
